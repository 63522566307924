<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Registro evaluación de la prueba de aptitud"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la responsable designado/a debe registrar la nota de la
                  <b>evaluación de la prueba de aptitud</b> obtenida por el/la postulante, en la
                  modalidad <b>A</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="getPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="options.search"
                        label="CI, nombre, apellidos"
                        :rules="[(v) => !!v || '']"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                   <!--    <v-btn
                        class="ml-1"
                        type="button"
                        large
                        color="accent"
                        @click="confirmClose"
                        :disabled="postulantes.length == 0"
                      >
                        Finalizar
                      </v-btn> -->
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.archivo`]="{ item }">
                    <td>
                      <a
                        v-if="item.prueba_aptitud"
                        :href="`${serve}aptitud/${item.prueba_aptitud.archivo}`"
                        target="_blank"
                        small
                        text
                        >clic para ver</a
                      >
                    </td>
                  </template>
                  <!-- <template v-slot:[`item.accion`]="{ item }"> -->
                    <template >
                    <td>
                      <!-- <v-btn
                        v-if="item.prueba_aptitud == null"
                        small
                        color="success"
                        @click="
                          confirmRegistro(
                            item.id,
                            item.a_persona.nombre_completo,
                            item.prueba_aptitud
                          )
                        "
                      >
                        Registrar
                      </v-btn>
                      <v-btn
                        v-else-if="
                          item.prueba_aptitud && !item.prueba_aptitud.estado
                        "
                        small
                        color="success"
                        @click="
                          confirmRegistro(
                            item.id,
                            item.a_persona.nombre_completo,
                            item.prueba_aptitud
                          )
                        "
                      >
                        Modificar
                      </v-btn>
                      <v-btn v-else small disabled> Modificar </v-btn> -->
                    </td>
                  </template>
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con la
                    <b>Cédula Identidad o DNA</b> ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="registerDialog" width="350">
      <v-card>
        <v-form ref="formr" @submit.prevent="saveAptitud()">
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Alerta</span>
          </v-card-title>
          <v-card-text class="text-center">
            <p>
              ¿Está seguro(a) de registrar la nota del postulante
              <b class="blue--text">{{ postulante }}</b> de la
              <b>Evaluación de la prueba de aptitud</b>?
            </p>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-text-field
                  v-model="prueba.nota"
                  type="number"
                  label="Nota"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  clearable
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Formulario de aptitud"
                  @click="pickFile"
                  v-model="prueba.archivo"
                  append-icon="mdi-attachment"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  filled
                  readonly
                ></v-text-field>
                <input
                  type="file"
                  style="display: none"
                  ref="archivo"
                  accept="application/pdf"
                  @change="onFilePicked"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success" :loading="load_send" text
              >Aceptar</v-btn
            >
            <v-btn color="red" text @click="registerDialog = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text class="text-center">
          <p>
            ¿Está seguro(a) de finalizar con el registro de la
            <b>Evaluación de la prueba de aptitud</b>?
          </p>
          <p class="red--text">
            Se consolidaran los registros realizados y no podrán ser modificados.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="success"
            :loading="close_load"
            @click="closeAptitud"
            text
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-registro-aptitud",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_search: false,
    load_send: false,
    close_load: false,
    type_state: 1,
    cedula_dni: "",
    convocatoria: {
      id: null,
      cite: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      { text: "Apellidos y nombres", value: "a_persona.nombre_completo" },
      { text: "Cédula identidad", value: "a_persona.cedula_dni" },
      { text: "Teléfono/Celular", value: "a_persona.celular" },
      { text: "Especialidad", value: "especialidad.descripcion" },
      { text: "Nota", value: "prueba_aptitud.nota" },
      { text: "Archivo", value: "archivo" },
      { text: "Acciones", value: "accion" },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    postulantes: [],
    postulante: "",
    postulante_id: "",
    prueba: {
      nota: "",
      archivo: "",
      observacion: "",
      location: "aptitud",
    },
    archivo_aptitud: "",
    registerDialog: false,
    confirmDialog: false,
    serve: "",
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [8, 11];
    if (this.user && roles.includes(this.user.rid)) {
      this.options.institucion_id = this.user.ies;
      this.serve = Service.getServe();
      this.getModalidades();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    pickFile() {
      this.$refs.archivo.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.prueba.archivo = files[0].name;
        this.archivo_aptitud = files[0];
        /* if (files[0].size < 2057157) {
          this.prueba.archivo = files[0].name;
          this.archivo_aptitud = files[0];
        } else {
          this.$refs.archivo.value = "";
          this.prueba.archivo = "";
          this.archivo_aptitud = "";
          this.toast({
            color: "warning",
            tex: "El archivo no debe superar el peso permitido de <b>2MB</b>.",
          });
        } */
      } else {
        this.prueba.archivo = "";
        this.archivo_aptitud = "";
      }
    },

    getModalidades() {
      this.notify_load = true;
      axios
        .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
        .then((response) => {
          this.convocatoria = response.data.convocatoria
            ? response.data.convocatoria
            : { id: null, cite: "" };
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
          this.getPostulantes();
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/habilitados/aptitud/registro",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    confirmRegistro(id, nombre_completo, prueba_aptitud) {
      this.postulante = nombre_completo;
      this.postulante_id = id;
      if (this.$refs.archivo) this.$refs.archivo.value = "";
      this.prueba.archivo = "";
      this.archivo_aptitud = "";
      this.prueba.nota = prueba_aptitud ? prueba_aptitud.nota : "";
      this.registerDialog = true;
    },

    saveAptitud() {
      if (this.$refs.formr.validate()) {
        if (this.prueba.nota >= 0 && this.prueba.nota <= 100) {
          this.load_send = true;
          let fd = new FormData();
          fd.append("convocatoria_id", this.convocatoria.id);
          fd.append("institucion_id", this.options.institucion_id);
          fd.append("postulante_id", this.postulante_id);
          fd.append("nota", this.prueba.nota);
          fd.append("location", this.prueba.location);
          fd.append("observacion", this.prueba.observacion);
          fd.append("usuario_id", this.prueba.usuario_id);
          fd.append("archivo", this.archivo_aptitud);
          axios
            .post(Service.getUrlAPI() + "admision/prueba/aptitud", fd, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: Service.getToken(),
              },
            })
            .then((response) => {
              this.load_send = false;
              this.prueba.nota = "";
              this.prueba.archivo = "";
              this.$refs.archivo.value = "";
              this.prueba.observacion = "";
              if (this.$refs.formr) this.$refs.formr.reset();
              this.toast({ color: "success", text: response.data });
              this.registerDialog = false;
              this.getPostulantes();
            })
            .catch(() => {
              this.load_send = false;
              this.toast({ color: "error", text: "No se pudo confirmar" });
            });
        } else {
          this.toast({
            color: "info",
            text: "La nota de aptitud debe ser entre 0 y 100",
          });
        }
      } else {
        this.toast({
          color: "info",
          text: "Ingrese la nota y suba el formulario escaneado",
        });
      }
    },

    confirmClose() {
      this.confirmDialog = true;
    },

    closeAptitud() {
      this.close_load = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/cerrar/aptitud",
          {
            convocatoria_id: this.convocatoria.id,
            institucion_id: this.options.institucion_id,
          },
          Service.getHeader()
        )
        .then((response) => {
          this.close_load = false;
          if (response.status === 201) {
            this.confirmDialog = false;
            this.toast({ color: "success", text: response.data.message });
            this.getPostulantes();
          }
        })
        .catch((error) => {
          this.close_load = false;
          console.error("Error, no se pudo guardar", error);
        });
    },
  },
};
</script>

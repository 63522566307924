<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Evaluación"></header-title>
      <v-row class="mb-6">
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-text>
              DATOS PERSONALES
              <v-row class="mt-1">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="postulante.nombres"
                    label="Nombres"
                    filled
                    disabled
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="postulante.paterno"
                    label="Apellido paterno"
                    filled
                    disabled
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="postulante.materno"
                    label="Apellido materno"
                    filled
                    disabled
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <p class="mt-8">CARGOS AL QUE SE REGISTRO</p>
              <v-row class="mb-4" v-if="postulante_inscripcions.length > 0">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(item, index) in postulante_inscripcions"
                  :key="index"
                >
                  <v-card elevation="3">
                    <v-card-text>
                      <span class="text-h6 font-weight-medium">
                        {{ item.institucion }}
                        <p class="body-2">{{ item.institucion_descripcion }}</p>
                      </span>
                      <p><b>CARGO</b><br />{{ item.cargo }}</p>
                      <span
                        class="red--text"
                        v-if="
                          [39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49].includes(
                            item.cargo_id
                          )
                        "
                      >
                        En caso de estar inscrito a más de un cargo
                        administrativo, solo tendrá validez la primera.
                      </span>
                      <!-- {{ item }} -->
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="
                          showConfirmStart(
                            item.convocatoria_id,
                            item.cargo_id,
                            item.codigo_institucion,
                            item.institucion,
                            item.cargo
                          )
                        "
                        :loading="data_loading"
                        :disabled="init_test"
                      >
                        {{ text_button }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="my-4" v-else>
                <v-col cols="12" class="red--text">
                  No hay cargos habilitados para la fecha actual.
                </v-col>
              </v-row>
              <div v-if="estado == 'preguntas'">
                <h3 class="teal--text my-4">100 Preguntas (120 minutos)</h3>
                <!-- <h1 class="time-box mb-4">
                  {{ formattedTimeLeft }}
                </h1> -->
                <v-row>
                  <v-col cols="12" md="12">
                    <v-data-table
                      :headers="headers"
                      :mobile="true"
                      :items="postulante.preguntas"
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      hide-default-footer
                      class="elevation-1"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:item.pregunta="{ item }">
                        <p class="text-left subtitle-2 py-2">
                          {{ item.descripcion }}
                        </p>
                        <v-radio-group v-model="item.respuesta" rows>
                          <v-radio
                            v-for="(oitem, jdx) in item.evaluacion_opcions"
                            :key="jdx"
                            :label="oitem.opcion"
                            :value="jdx"
                            @change="saveLocalQuestion"
                          ></v-radio>
                        </v-radio-group>
                      </template>
                    </v-data-table>
                    <div class="text-center pt-2">
                      <v-pagination
                        v-model="page"
                        :length="pageCount"
                      ></v-pagination>
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn
                      large
                      color="primary"
                      @click="showConfirm"
                      class="mt-5"
                    >
                      <v-icon small>mdi-send</v-icon>
                      Finalizar Prueba
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="estado == 'tabla'">
                <h3 class="teal--text mt-4">Resultado de la Prueba</h3>
                <p class="my-2">
                  <b>{{ ver_institucion }}</b
                  ><br />
                  {{ ver_cargo }}
                </p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nombre completo</th>
                        <th class="text-center">Aciertos (#)</th>
                        <!-- <th class="text-center">Porcentaje (%)</th> -->
                        <th class="text-left">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in respuestas" :key="index">
                        <td>{{ item.nombre }}</td>
                        <td class="text-center">{{ item.acierto }}</td>
                        <!-- <td class="text-center">{{ item.porcentaje }}</td> -->
                        <td>
                          <span
                            v-if="
                              item.estado == 1 ||
                              item.estado == true ||
                              item.estado == 'true'
                            "
                          >
                            <v-chip small color="green lighten-4"
                              >activo</v-chip
                            >
                          </span>
                          <span v-else>
                            <v-chip small color="red lighten-4"
                              >inactivo</v-chip
                            >
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-else-if="data_loading" class="text-center">
                <v-progress-circular
                  color="blue"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-if="estado == 'mensaje'" class="text-center">
                <p class="red--text">{{ message }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="initDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de comenzar con la prueba?</p>
          <p>O en caso de haber realizado la prueba podrá ver el resultado.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :loading="send_loading" @click="checkResponse">
            Aceptar
          </v-btn>
          <v-btn color="error" @click="initDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendDialog" persistent width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de enviar y finalizar la prueba?</p>
          <p class="red--text">Una vez enviada no podrá modificar la prueba.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :loading="send_loading" @click="sendResult">
            Aceptar
          </v-btn>
          <v-btn color="error" @click="sendDialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      :timeout="snack.time"
      :tile="snack.tile"
      content-class="text-h6s font-weight-mediums"
      :centered="true"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../utils/general";
import axios from "axios";

const TIME_LIMIT = 7200; //3600;

export default {
  name: "institucionalizacion-evaluacion",
  components: {
    HeaderTitle,
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 1,
    headers: [
      {
        text: "",
        sortable: false,
        value: "pregunta",
      },
    ],

    initDialog: false,
    sendDialog: false,
    loading: false,
    question_loading: false,
    data_loading: false,
    send_loading: false,
    btn_loading: false,
    server: "",
    estado: "",
    init_test: false,
    text_button: "COMENZAR",
    message: "",
    respuestas: [],
    postulante: {
      id: "",
      nombres: "",
      paterno: "",
      materno: "",
      preguntas: [],
      acierto: 0,
      porcentaje: 0,
      convocatoria_id: "",
      cargo_id: "",
      codigo_institucion: "",
      tiempo: "",
    },
    postulante_inscripcions: [],
    ver_institucion: "",
    ver_cargo: "",
    snack: {
      state: false,
      color: "success",
      text: "",
      time: "2500",
      tile: false,
    },

    timePassed: 0,
    timerInterval: null,
  }),
  mounted() {
    this.server = Service.getServe();
    let user = Service.getUser();
    if (user) {
      this.postulante.id = user.uid;
      this.searchPostulante(this.postulante.id);
    } else {
      if (this.$router.currentRoute.name != "root") {
        this.$router.push({ name: "root" });
      }
    }
    let preguntas = Service.getQuestion();
    if (preguntas) {
      this.estado = "preguntas";
      this.init_test = true;
      this.text_button = "EN PROCESO";
      this.postulante.preguntas = preguntas;

      let params = Service.getParams();
      this.postulante.convocatoria_id = params.convocatoria_id;
      this.postulante.codigo_institucion = params.codigo_institucion;
      this.postulante.cargo_id = params.cargo_id;
      // this.timePassed = Service.getTime() * 1;
      // this.startTimer();
    }
  },
  computed: {
    acierto() {
      return this.postulante.preguntas.reduce(
        (sum, item) =>
          sum +
          (item.respuesta != null
            ? item.evaluacion_opcions[item.respuesta].respuesta_correcta ===
              true
              ? 1
              : 0
            : 0),
        0
      );
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },
  },
  /* watch: {
      timeLeft(newValue) {
        if (newValue === 0) {
          this.onTimesUp();
          // this.prepareSendResult();
        }
        // else {
        //   Service.setTime(this.timePassed);
        // }
      },
    }, */
  methods: {
    /* onTimesUp() {
        clearInterval(this.timerInterval);
      },

      startTimer() {
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
      }, */

    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },

    searchPostulante() {
      // this.btn_loading = true;
      // this.estado = "";
      this.postulante.nombres = "";
      this.postulante.paterno = "";
      this.postulante.materno = "";
      this.postulante.preguntas = [];
      axios
        .post(
          Service.getUrlAPI() + "busca/postulante",
          { id: this.postulante.id },
          Service.getHeader()
        )
        .then((response) => {
          // this.btn_loading = false;
          if (response.data.id > 0) {
            this.postulante.id = response.data.id;
            this.postulante.nombres = response.data.nombres;
            this.postulante.paterno = response.data.paterno;
            this.postulante.materno = response.data.materno;
            this.postulante_inscripcions =
              response.data.postulante_inscripcions;
          } else {
            this.toast("info", "Registro no encontrado");
          }
        })
        .catch(() => {
          // this.btn_loading = false;
          console.error("Postulante no encontrado");
        });
    },

    /* getQuestions() {
        this.question_loading = true;
        axios
          .get(Service.getUrlAPI() + "lista/preguntas/" + this.postulante.cargo_id)
          .then(response => {
            this.question_loading = false;
            this.postulante.preguntas = response.data;
          })
          .catch(error => {
            this.question_loading = false;
            console.error("Error al cargar datos", error);
          });
      }, */
    showConfirmStart(
      convocatoria_id,
      cargo_id,
      codigo_institucion,
      institucion,
      cargo
    ) {
      this.postulante.convocatoria_id = convocatoria_id;
      this.postulante.cargo_id = cargo_id;
      this.postulante.codigo_institucion = codigo_institucion;
      this.ver_institucion = institucion;
      this.ver_cargo = cargo;
      this.estado = "";
      this.initDialog = true;

      Service.setParams(convocatoria_id, cargo_id, codigo_institucion);
    },

    checkResponse() {
      this.estado = "";
      this.postulante.preguntas = [];
      this.data_loading = true;
      axios
        .post(
          Service.getUrlAPI() + "check/respuesta",
          {
            postulante_id: this.postulante.id,
            codigo_institucion: this.postulante.codigo_institucion,
            cargo_id: this.postulante.cargo_id,
          },
          Service.getHeader()
        )
        .then((response) => {
          this.initDialog = false;
          this.data_loading = false;
          if (response.data.message == "not") {
            if (response.data.result.length > 0) {
              this.estado = "preguntas";
              this.postulante.preguntas = response.data.result;

              // this.saveLocalQuestion();
              this.init_test = true;
              this.text_button = "EN PROCESO";
              // this.startTimer();
            } else {
              this.estado = "mensaje";
              this.message = "El cargo no tiene preguntas";
            }
          } else {
            this.estado = "tabla";
            this.init_test = false;
            this.text_button = "COMENZAR";
            this.respuestas = response.data.result;
          }
        })
        .catch((error) => {
          this.data_loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    async showConfirm() {
      this.send_loading = false;
      let responseExist = await this.postulante.preguntas.find(
        (item) => item.respuesta != null
      );
      if (responseExist) {
        this.postulante.acierto = this.acierto;
        if (this.acierto > 0) {
          let cantidadPreguntas = this.postulante.preguntas.length;
          this.postulante.porcentaje = Math.round(
            (this.acierto / cantidadPreguntas) * 100
          );
        }
        this.sendDialog = true;
      } else {
        this.toast("warning", "Debe marcar las respuestas.");
      }
      console.log(this.postulante);
    },

    prepareSendResult() {
      this.postulante.acierto = this.acierto;
      if (this.acierto > 0) {
        let cantidadPreguntas = this.postulante.preguntas.length;
        this.postulante.porcentaje = Math.round(
          (this.acierto / cantidadPreguntas) * 100
        );
      }
      console.log(this.postulante);
      this.sendResult();
    },

    sendResult() {
      this.send_loading = true;
      // this.onTimesUp();
      axios
        .post(
          Service.getUrlAPI() + "respuesta",
          this.postulante,
          Service.getHeader()
        )
        .then(async (response) => {
          this.send_loading = false;
          if (response.status === 201) {
            this.sendDialog = false;
            this.toast("success", response.data.message);
            this.estado = "";
            this.postulante.preguntas = [];
            Service.removeAll();
            // this.checkResponse(this.postulante.id, this.postulante.cargo_id);
            await this.checkResponse();
          } else if (response.status === 200) {
            this.toast("info", response.data.message);
          }
        })
        .catch((error) => {
          this.send_loading = false;
          console.error("Error, no se pudo guardar", error);
        });
    },

    saveLocalQuestion() {
      localStorage.setItem(
        "question",
        JSON.stringify(this.postulante.preguntas)
      );
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
<style lang="css">
.v-data-table .v-data-table-header,
table thead {
  background-color: #f5f5f5;
}
.time-box {
  background: #f2f2f2;
  padding: 12px;
  text-align: center;
  width: 200px;
  font-size: 3.5em;
}
</style>

<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Consultar resultado final oficial de admisión a las ESFM/UA's MODALIDAD B"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la postulante puede consultar los resultados de
                  <b>evaluación de la lengua originaria y resultado final</b>
                </p>
                <p>
                  Ingrese su
                  <b>cédula de identidad, complemento y fecha de nacimiento</b>. Tal como ingresó durante la
                  inscripción.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulante()">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="postulante.a_postulante.a_persona.cedula_dni"
                        label="Cédula de identidad"
                        :rules="[
                          (v) => !!v || 'El campo es requerido',
                          (v) => (!!v && v.length > 3) || 'Mínimo 4 dígitos',
                        ]"
                        hint="1234567"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-text-field v-model.trim="postulante.a_postulante.a_persona.complemento" maxlength="2" label="Complemento" :rules="[
                      ]" hint="1G" clearable filled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthDateFormatted"
                            label="Fecha de nacimiento"
                            append-icon="mdi-calendar"
                            :rules="[(v) => !!v || 'El campo es requerido']"
                            filled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            postulante.a_postulante.a_persona.fecha_nacimiento
                          "
                          @input="menu_date = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <div v-if="type_state == 3">
                  <h4 class="grey--text">DATOS PERSONALES</h4>
                  <v-row>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Apellidos y Nombres</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            {{
                              postulante.a_postulante.a_persona.nombre_completo
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Genero</v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              postulante.a_postulante.a_persona.genero
                                ? postulante.a_postulante.a_persona.genero
                                    .descripcion
                                : ""
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>RESULTADO</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 25px !important;color: #ff0000;">
                            {{ postulante.a_postulante.estado_id==9 ?"ADMITIDO":"NO ADMITIDO" }}
                            
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <h4 class="grey--text mt-5">DATOS DE POSTULACIÓN</h4>
                  <v-row>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>ESFM/UA</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.institucion.nombre }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Especialidad</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.a_postulante.especialidad.descripcion }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <!-- <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Nota</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.nota_aptitud }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col> -->
                  </v-row>
                  <!-- <h4 class="grey--text mt-5">RESULTADOS</h4> -->
                 
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con
                    <b>Cédula de Identidad y Fecha de Nacimiento</b>
                    ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../services/request";
import { mapActions } from "vuex";

export default {
  name: "admision-consultar-butaca",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    menu_date: false,
    type_state: 1,
    convocatoria: {
      id: 0,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    postulante: {
      id:"",
      nota_aptitud: "",
      a_postulante: {
        id:"",
        estado_id: "",
        a_persona: {
          nombre_completo: "",
          nombres: "",
          paterno: "",
          materno: "",
          cedula_dni: "",
          fecha_nacimiento: "",
          complemento:"",
          genero: {},
        },
        especialidad: {},
      },
      institucion: {},
    },
  }),
  computed: {
    birthDateFormatted() {
      if (!this.postulante.a_postulante.a_persona.fecha_nacimiento) return null;

      const [year, month, day] =
        this.postulante.a_postulante.a_persona.fecha_nacimiento.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  created() {
    this.getAnnouncement();
  },
  methods: {
    ...mapActions(["toast"]),
    getAnnouncement() {
      this.notify_load = true;
      Service.svcAdmissionAnnouncement()
        .then((response) => {
          this.convocatoria = response.data;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_title = "Notificación";
          this.notify_load = false;
        });
    },
    searchPostulante() {
      /* this.postulante = {
        a_postulante: {
          id: "",
          persona: {
            cedula_dni: "",
            paterno: "",
            materno: "",
            nombres: "",
            nombre_completo: "",
            fecha_nacimiento: "",
          },
        },
        institucion: {},
        especialidad: {},
        butaca: "",
        id: "",
      }; */
      this.postulante.a_postulante.a_persona.nombre_completo = "";
      this.postulante.a_postulante.a_persona.genero = {};
      // this.postulante.a_postulante.persona.cedula_dni = "";
      this.postulante.institucion = {};
      this.postulante.a_postulante.especialidad = {};
      this.postulante.nota_aptitud = "-";
      if (this.$refs.formp.validate()) {
        this.load_search = true;
        this.type_state = 1;
        Service.svcCheckResultB({
          convocatoria_id: this.convocatoria.id,
          cedula_dni: this.postulante.a_postulante.a_persona.cedula_dni,
          fecha_nacimiento: this.postulante.a_postulante.a_persona.fecha_nacimiento,
          complemento : this.postulante.a_postulante.a_persona.complemento,
        })
          .then((response) => {
            this.load_search = false;
            if (response.status == 200) {
              this.type_state = 3;
              this.postulante = response.data;
            } else {
              this.type_state = 2;
            }
          })
          .catch(() => {
            this.load_search = false;
          });
      } else {
        this.type_state = 1;
        this.toast({
          color: "info",
          text: "Ingrese Cédula de identidad y Fecha de nacimiento",
        });
      }
    },
  },
};
</script>
<style scoped>
table thead th {
  background-color: #e0e0e0;
}
table,
th,
td {
  border: 1px solid #bdbdbd;
  border-collapse: collapse;
}
</style>
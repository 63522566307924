<template>
  <v-card class="text-center">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text v-if="load">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-card-text>
    <v-card-text v-else>
      <h3 class="mt-2">No hay convocatoria habilitada para {{ process }}.</h3>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title", "process", "load"],
};
</script>

<template>
  <v-container grid-list-xl fluid>
    <header-title title="Informe estadístico"></header-title>
    <v-row class="mb-6 text-center">
      <v-col cols="12" md="6" class="py-1">
        <v-select
          label="Convocatorias"
          v-model="convocatoria_id"
          :items="convocatorias"
          item-text="convocatoria"
          item-value="id"
          hide-details
          filled
          @change="getStatistic"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-text>
            <p class="mb-0">
              El reporte es valido previo a la <b>prueba escrita</b>, posterior
              a ello los datos ya no serán precisas.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="convocatoria_id != ''">
      <v-row class="mb-6">
        <v-col cols="12" md="6">
          <v-card class="text-center">
            <v-card-text>
              <v-btn
                :disabled="dato.modalidades.length == 0"
                color="error"
                @click="statisticalReport"
                >Generar informe</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card class="mt-3">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="head-color-1">
                    <th
                      class="text-center subtitle-1 font-weight-medium"
                      colspan="2"
                    >
                      CANTIDAD DE POSTULANTES INSCRITOS
                    </th>
                  </tr>
                  <tr class="head-color-2">
                    <th class="text-left">Modalidad</th>
                    <th class="text-left">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dato.modalidades" :key="item.name">
                    <td>{{ item.sigla }}</td>
                    <td :class="item.sigla == 'A' ? 'blue--text' : ''">
                      {{ item.cantidad }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center">Total</th>
                    <th class="text-left">
                      {{ totalGral }}
                    </th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="head-color-1">
                    <th
                      class="text-center subtitle-1 font-weight-medium"
                      colspan="2"
                    >
                      MODALIDAD A
                    </th>
                  </tr>
                  <tr class="head-color-2">
                    <th class="text-left" width="65%">
                      Cantidad de postulantes con prueba de aptitud
                    </th>
                    <th class="text-left" width="35%">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dato.prueba_aptitud" :key="item.name">
                    <td>{{ item.descripcion }}</td>
                    <td>{{ item.cantidad }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center">Total habilitados</th>
                    <th class="text-left blue--text">{{ totalAptitude }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
          <v-card class="mt-3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="head-color-2">
                    <th class="text-left" width="65%">
                      Cantidad de habilitados a prueba escrita
                    </th>
                    <th class="text-left" width="35%">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dato.prueba_escrita" :key="item.name">
                    <td>{{ item.descripcion }}</td>
                    <td :class="item.estado == 3 ? 'blue--text' : ''">
                      {{ item.cantidad }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center">Total habilitados</th>
                    <th class="text-left">{{ totalWritten }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
          <v-card class="mt-3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="head-color-2">
                    <th class="text-left" width="65%">
                      Cantidad de observados
                    </th>
                    <th class="text-left" width="35%">Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in dato.observados" :key="item.name">
                    <td>{{ item.descripcion }}</td>
                    <td>{{ item.cantidad }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center">Total observados y NSP</th>
                    <th class="text-left blue--text">{{ totalObserved }}</th>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="head-color-1">
                  <th
                    class="text-center subtitle-1 font-weight-medium"
                    colspan="4"
                  >
                    CANTIDAD DE POSTULANTES INSCRITOS POR ESFM/UA
                  </th>
                </tr>
                <tr class="head-color-2">
                  <th class="text-left">No.</th>
                  <th class="text-left">DEPARTAMENTO</th>
                  <th class="text-left">ESFM/UA</th>
                  <th class="text-left">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dato.instituciones" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="6">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr class="head-color-1">
                  <th
                    class="text-center subtitle-1 font-weight-medium"
                    colspan="4"
                  >
                    CANTIDAD DE HABILITADOS A EVALUACIÓN DE LA LENGUA ORINARIA POR ESFM/UA
                  </th>
                </tr>
                <tr class="head-color-2">
                  <th class="text-left">No.</th>
                  <th class="text-left">DEPARTAMENTO</th>
                  <th class="text-left">ESFM/UA</th>
                  <th class="text-left">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dato.aptitudes" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <span class="red--text">No hay registros para visualizar.</span>
    </div>
  </v-container>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import axios from "axios";

export default {
  name: "admission-statistical-report",
  components: {
    HeaderTitle,
  },
  data: () => ({
    message: "",
    loading: false,
    convocatoria_id: "",
    convocatorias: [],
    dato: {
      modalidades: [],
      prueba_aptitud: [],
      prueba_escrita: [],
      observados: [],
      instituciones: [],
      aptitudes: [],
    },
    user: null,
  }),
  computed: {
    totalGral() {
      return this.dato.modalidades.reduce(
        (a, b) => a + (parseInt(b.cantidad) || 0),
        0
      );
    },
    totalAptitude() {
      return this.dato.prueba_aptitud.reduce(
        (a, b) => a + (parseInt(b.cantidad) || 0),
        0
      );
    },
    totalWritten() {
      return this.dato.prueba_escrita.reduce(
        (a, b) => a + (parseInt(b.cantidad) || 0),
        0
      );
    },
    totalObserved() {
      return this.dato.observados.reduce(
        (a, b) => a + (parseInt(b.cantidad) || 0),
        0
      );
    },
  },
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [1, 2,13];
    if (this.user && roles.includes(this.user.rid)) {
      this.getAnnouncement();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    getAnnouncement() {
      axios
        .get(
          Service.getUrlAPI() + "admision/convocatorias",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatorias = response.data;
          this.convocatoria_id =
            this.convocatorias[this.convocatorias.length - 1].id;
          this.getStatistic();
        })
        .catch(() => {
          console.error("Parametros no encontrado");
        });
    },
    getStatistic() {
      axios
        .get(
          Service.getUrlAPI() +
            "admision/informe/estadistico/" +
            this.convocatoria_id,
          Service.getHeader()
        )
        .then((response) => {
          this.dato = response.data;
        })
        .catch(() => {
          console.error("datos no encontrado");
        });
    },
    statisticalReport() {
      let conv = this.convocatorias.find(x=>x.id=this.convocatoria_id);
      if(conv){
        const announcement = conv;//response.data;

        const docDefinition = {
          pageSize: "LETTER",
          pageMargins: [42, 70, 42, 30],
          header: PDFService.setting().header,
          footer: PDFService.setting().footer,
          content: PDFService.statisticalReportPDF(
            announcement,
            this.dato,
            this.totalGral,
            this.totalAptitude,
            this.totalWritten,
            this.totalObserved
          ),
        };
        pdfMake.createPdf(docDefinition).open();
      }else{
        console.error("convocatoria no encontrada");
      }
      

      // axios
      //   .get(
      //     Service.getUrlAPI() +
      //       "admision/obtener/convocatoria/" +
      //       this.convocatoria_id,
      //     Service.getHeader()
      //   )
      //   .then((response) => {
          
      //   })
      //   .catch(() => {
      //     console.error("datos no encontrado");
      //   });
    },
  },
};
</script>
<style scoped>
.head-color-1 {
  background-color: #616161;
}
.head-color-1 th {
  color: white !important;
}
.head-color-2 {
  background-color: #e0e0e0;
}
</style>

<template>
  <v-container grid-list-xl fluid>
    <v-row class="mb-2 mb-md-1 mb-lg-2 text-center">
      <v-col cols="12" sm="12">
        <center>
          <img
            v-bind:src="require('../assets/logo-minedu.png')"
            alt="User"
            width="40%"
          />
        </center>
        <br />
        <h3 class="text-h6 text-md-h5 text-lg-h3 mt-1 mt-md-1 mt-lg-2">
          <!-- CONVOCATORIA PÚBLICA No. 001/2022 -->
          {{ convocatoria.cite }}
        </h3>
        <p class="text-subtitle-1 text-md-h5 text-lg-h4 mt-1 mt-md-1 mt-lg-2">
          <!-- PROCESO DE ADMISIÓN DE POSTULANTES A LAS ESCUELAS SUPERIORES DE
          FORMACIÓN DE MAESTROS Y UNIDADES ACADÉMICAS GESTIÓN 2023 -->
          {{ convocatoria.descripcion }}
        </p>
        <!-- <p class="text-h4 mt-5">
          SEGUNDA CONVOCATORIA PUBLICA N°001/2021 PROCESO DE
          INSTITUCIONALIZACIÓN DE CARGOS DIRECTIVOS, DOCENTES Y ADMINISTRATIVOS
          DE LAS ESCUELAS SUPERIORES DE FORMACION DE MAESTRO Y UNIDADES
          ACADÉMICAS DEL ESTADO PLURINACIONAL DE BOLIVIA
        </p> -->
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Nomina de admitidos
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                PROCESO DE ADMISIÓN 2022
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/admitidos_modalidad_a_2022.pdf"
                  target="_blank"
                  download
                >
                  Modalidad A
                </v-btn>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/admitidos_modalidad_b_2022.pdf"
                  target="_blank"
                  download
                >
                  Modalidad B
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Consultar resultado final
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                RESULTADOS DE PRUEBA DE APTITUD Y ESCRITA
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  to="/admision/consultar/resultado"
                >
                  Ir al formulario
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Consulte su resultado
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                RESULTADOS DE LA PRUEBA ESCRITA
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="https://veresultado.com/appss/admision2022/"
                  target="_blank"
                >
                  Ir al formulario
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#B2EBF2" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Consultar butaca
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                <b>VERIFIQUE</b> Y CONSULTE SU BUTACA ACORDE A LOS RECINTOS
                PUBLICADOS
              </v-card-subtitle>
              <v-card-actions>
                <v-btn color="#546E7A" outlined to="/admision/consultar/butaca">
                  Ir al formulario
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Recintos para la Prueba Escrita
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD A
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/recintos_pruebaescrita_2022.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row> 
    <v-row class="mb-6">
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Lista de habilitados a Prueba Escrita
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD A
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/habilitados_prueba_escrita.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- <v-row class="mb-6">
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#DCEDC8" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Lista de Admitidos
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                PROCESO DE ADMISIÓN GESTIÓN 2023
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/admitidos_modalidad_a_2023.pdf"
                  target="_blank"
                  download
                >
                  Modalidad A
                </v-btn>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/admitidos_modalidad_b_2023.pdf"
                  target="_blank"
                  download
                >
                  Modalidad B
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card> -->
        <!-- <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#DCEDC8" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Consultar resultado final
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                RESULTADOS DE MODALIDAD A
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  to="/admision/consultar/resultado"
                >
                  Ir al formulario
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card> 
      </v-col>-->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Consultar resultado final
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                RESULTADOS DE MODALID B
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  to="/admision/consultar/resultadoB"
                >
                  Ir al formulario
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/ADM Nº 005/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                (21-Febrero-2023)
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/comunicado05_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>

       
      </v-col> 
    </v-row>-->
   <!-- <v-row class="mb-6">
       <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile>
              <v-card-title class="green--text text--darken-4 text-h6">
                Resultado de Prueba Escrita
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver resultado haciendo clic en el siguiente botón
                 Consulte su resultado ingresando su Número de Butaca, Carnet de Identidad y Fecha de nacimiento. 
                <br/>
              Deber ingresar su fecha de nacimiento en el siguiente formato: "ddmmaaaa". Por ejemplo, si nació el 14/02/2000 debe ingresar: 14022000 sin barras ("/") ni guiones  ("-").
              </v-card-subtitle>
               <v-card-text class="grey--text text--darken-3">
                
              </v-card-text> 
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="https://veresultado.com/appss/admision2023/"
                  target="_blank"
                  download
                >
                  Ver Resultado
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
     
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                RECINTOS - PRUEBA ESCRITA MODALIDAD A
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
               (06-02-2023)
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/sedes001_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/ADM Nº 004/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                (06-Febrero-2023)
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/comunicado04_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col> 

      
       
      
   </v-row> -->
    <!-- <v-row class="mb-6">
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile>
              <v-card-title class="blue--text text--darken-4 text-h6">
                Modificación de Cronograma
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/cronograma002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Lista de habilitados a Prueba Escrita 
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD A
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//drive.google.com/file/d/1ag7v4mprgN2zoRxxiXArhzY6gKUmgk6P/view?usp=share_link"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
     
      
      
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/ADM Nº 003/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                ACLARACIONES IMPORTANTES (19-Enero-2023)
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/comunicado_03_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
      
      
      
    </v-row>  -->
     <!-- <v-row class="mb-6">
     <v-col cols="12" md="4" sm="6">
        
      <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile>
              <v-card-title class="blue--text text--darken-4 text-h6">
                Cronograma 
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                APLICACiÓN DE LA PRUEBA DE APTITUD
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/CRONOGRAMA_PRUEBA_DE_APTITUD.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        
        <v-card>
          <v-card-text>
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Lista de habilitados a Prueba de Aptitud 
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD B (Actualizada)
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/listas/HABILITADOS_PRUEBA_APTITUD_MOD_B.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/ADM Nº 002/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                ACLARACIONES IMPORTANTES (14-Enero-2023)
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/comunicado_02_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col> -->
     
<!-- 
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1 text-h6">
                IT/VESFP/DGFM No. 0013/2022
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MATRICULACIÓN DE INICIO DE ACTIVIDADES, ESTUDIANTES DE PRIMER
                AÑO
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1645819871913.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="mt-3" color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1 text-h6">
                IT/VESFP/DGFM No. 0008/2022
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                PROCESO DE ADMISIÓN DE POSTULANTES A LAS ESFM/UAs, GESTIÓN 2022
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1643756510502.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="mt-3" color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1 text-h6">
                IT/VESFP/DGFM No. 0004/2022
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                POSTERGACIÓN DE PRUEBA ESCRITA
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1641942516309.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Nota 2</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Para acceder a la Evaluación ingrese con su documento de identidad
              con el que se registró.
            </p>
            <p>Por ejemplo:</p>
            <div class="pl-5">
              <p><b>Usuario: </b>1234567</p>
              <p class="mb-1"><b>Contraseña: </b>1234567</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Nota 3</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Para evitar percances debe prever las siguientes particularidades
              que podría causar problemas durante la prueba.
            </p>
            <p>Tomar en cuenta lo siguiente:</p>
            <div class="pl-5">
              <span>Tener conexión a internet.</span>
              <br />
              <span>Contar con batería cargada de su dispositivo.</span>
              <br />
              <span>O conectar a una fuente de alimentación.</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col> 
    </v-row>
    <v-row>-->
      <!-- <v-col cols="12" md="4" sm="6">
        
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card class="mt-3" color="#B2EBF2" tile>
              <v-card-title class="blue--text text--darken-4 text-h6">
                Reglamento
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/Reglamento_admision_2022_s.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
        
      </v-col> -->
      <!-- <v-col  cols="12" sm="4">
        
        <v-card>
          <v-card-text>
            <v-card color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Lista de habilitados a Prueba de Aptitud 
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD A
              </v-card-subtitle>
              <v-card-text class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//drive.google.com/drive/folders/1pmDTd8PskZwqTtwSi_HzVroV7xygOS6_?usp=share_link"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>  -->
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/ADM Nº 001/2022
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                ACLARACIONES IMPORTANTES
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/001_2022.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card></v-col> 
    </v-row> -->



    <v-row>
     
        <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card class="mt-3" color="#B2EBF2" tile>
              <v-card-title class="blue--text text--darken-4 text-h6">
                Convocatoria
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/convocatoria_admision_2025.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
          </v-card>
        </v-col>
        <v-col  cols="12" sm="4">
      
        
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card class="mt-3" color="#F0F4C3" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Reglamento
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/reglamento_admision_2025.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
    
        </v-col>
        <v-col  cols="12" sm="4">
          
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Sedes y Recintos
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Aplicación de la prueba escrita del proceso de admisión ESFM/UA gestión 2025 
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/recintos.pdf"
                  target="_blank"
                  download
                  >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> 
    <br/>
    <hr/>
    <br/>
    <v-row>
      <v-col offset-sm="1" cols="12" sm="10">
        
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#DCEDC8" tile>
              <v-card-title class="teal--text text--darken-4 text-h6">
                Postulantes habilitados a la prueba escrita
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                MODALIDAD A
              </v-card-subtitle>
              <v-row class="container justify-content-md-center">
                <v-col  class="col" style="text-align: center;" >
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-BENI.pdf"
                    target="_blank"
                    download
                    >
                    BENI
                    </v-btn>
                </v-col>
                <v-col class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-CHUQUISACA.pdf"
                    target="_blank"
                    download
                    >
                    CHUQUISACA
                    </v-btn>
                </v-col>
                <v-col class="col" style="text-align: center;" >
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-COCHABAMBA.pdf"
                    target="_blank"
                    download
                    >
                    COCHABAMBA
                    </v-btn>
                </v-col>
             
                <v-col  class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-LAPAZ.pdf"
                    target="_blank"
                    download
                    >
                    LA PAZ
                    </v-btn>
                </v-col>
                <v-col  class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-ORURO.pdf"
                    target="_blank"
                    download
                    >
                    ORURO
                    </v-btn>
                </v-col>
                <v-col class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-PANDO.pdf"
                    target="_blank"
                    download
                    >
                    PANDO
                    </v-btn>
                </v-col>
             
                <v-col  class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-POTOSI.pdf"
                    target="_blank"
                    download
                    >
                    POTOSÍ
                    </v-btn>
                </v-col>
                <v-col  class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-SANTACRUZ.pdf"
                    target="_blank"
                    download
                    >
                    SANTA CRUZ
                    </v-btn>
                </v-col>
                <v-col  class="col" style="text-align: center;">
                    <v-btn 
                    color="#546E7A"
                    outlined
                    href="//dgfmapi.minedu.gob.bo/admision/comunicados/2025/HabModA-TARIJA.pdf"
                    target="_blank"
                    download
                    >
                    TARIJA
                    </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> 
    <br/>
    <hr/>
    <br/>
    <v-row>
      
      <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 011
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Consideraciones para el ingreso a los recintos del Proceso de Admisión de Postulantes a las Escuelas Superiores de Formación de Maestras y Maestros y Unidades Académicas del Estado Plurinacional de Bolivia – Gestión 2025. 
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1737762796294.pdf"
                  target="_blank"
                  download
                >Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 012
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Causales de anulación de la Prueba Escrita del Proceso de Admisión de Postulantes a las Escuelas Superiores de Formación de Maestras y Maestros y Unidades Académicas del Estado Plurinacional de Bolivia – Gestión 2025. 
                </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1737762911186.pdf"
                  target="_blank"
                  download
                >Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 013
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Postergación de la prueba escrita de dos recintos del departamento de La Paz: Coliseo "Héroes de Octubre" y Coliseo Cerrado "Julio Borelli Viterito"  
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1737813951097.pdf"
                  target="_blank"
                  download
                >Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> 
   
    <v-row>
      
      <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 005
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Postulante con discapacidad.
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo//normativas/1736511476208.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
        </v-col>
        <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 007
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Formularios de la Prueba de Aptitud y Valoración de la Lengua Originaria.
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo//normativas/1736747900972.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 008
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Cumplimiento al cronograma - Postulación vía internet, para las Modalidades "A" y "B"
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo//normativas/1737140200288.pdf"
                  target="_blank"
                  download
                >Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 001
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Entrega de requisitos de postulantes de la Unidad Académica "San Pedro de Quemes".
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1735670401717.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
        </v-col>
        <v-col  cols="12" sm="4">
          <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 002
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Depósitos por el derecho de postulación realizados solo con el nombre y apellido del postulante VÁLIDOS. 
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1735877613862.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
        </v-col>
        <v-col  cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado 004
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Cronograma de postulación. 
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/normativas/1736394101527.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   
    





    <v-row>

      <v-col offset-sm="4" cols="12" sm="4">
      <v-card>
          <v-card-title>Nota importante</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Para usar el sistema se recomienda utilizar los siguientes
              navegadores web, a razón del uso de las nuevas tecnologías.
            </p>
            <p>Navegadores:</p>
            <div class="pl-5">
              <a href="https://www.google.com/intl/es/chrome/" target="_blank">
                <b>Chrome</b> en su ultima preferentemente
              </a>
              <br />
              <a
                href="https://www.mozilla.org/es-ES/firefox/new/"
                target="_blank"
              >
                <b>Firefox</b> en su ultima preferentemente
              </a>
              <br />
              <a href="https://www.opera.com/es" target="_blank">
                <b>Opera</b> en su ultima preferentemente
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Service from "../utils/general";
import Service from "../services/request";
export default {
  name: "inicio",
  data: () => ({
    message: "",
    loading: false,
    convocatoria: {
      id: 0,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    cite:""
  }),
  mounted() {},
  created() {
    this.getAnnouncement();
  },
  methods: {
    getAnnouncement() {
      this.notify_load = true;
      Service.svcAdmissionAnnouncement()
        .then((response) => {
          this.convocatoria = response.data;
          this.convocatoria.cite = this.convocatoria.cite.toUpperCase();
          this.convocatoria.descripcion = this.convocatoria.descripcion.toUpperCase()+ ' GESTIÓN '+ this.convocatoria.gestion;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_title = "Notificación";
          this.notify_load = false;
        });
    },
  },
};
</script>
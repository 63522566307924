<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Consultar butaca"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la postulante para ver el <b>número de butaca</b> tiene que
                  estar en la nomina de habilitados a la prueba escrita de la
                  modalidad <b>A</b>.
                </p>
                <p>
                  En la caja de texto de abajo ingrese su
                  <b>cédula de identidad</b> tal como ingresó durante la
                  inscripción y su <b>complemento</b> en caso de tenerlo.
                </p>
                <p class="teal--text mb-0">
                  <b>
                    NOTA: VERIFIQUE Y CONSULTE SU BUTACA ACORDE A LOS RECINTOS
                    PUBLICADOS
                  </b>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulante()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="postulante.a_postulante.a_persona.cedula_dni_f"
                        label="Cédula de identidad"
                        :rules="[
                          (v) => !!v || 'El campo es requerido',
                          (v) => (!!v && v.length > 3) || 'Mínimo 4 dígitos',
                        ]"
                        hint="1234567"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-text-field v-model.trim="postulante.a_postulante.a_persona.complemento" maxlength="2" label="Complemento" :rules="[
                      ]" hint="1G" clearable filled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthDateFormatted"
                            label="Fecha de nacimiento"
                            append-icon="mdi-calendar"
                            :rules="[(v) => !!v || 'El campo es requerido']"
                            filled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            postulante.a_postulante.a_persona.fecha_nacimiento
                          "
                          @input="menu_date = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <div v-if="type_state == 3">
                  <h4 class="grey--text">DATOS PERSONALES</h4>
                  <v-row>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Apellidos y Nombres</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            {{
                              postulante.a_postulante.a_persona.nombre_completo
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Genero</v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              postulante.a_postulante.a_persona.genero
                                ? postulante.a_postulante.a_persona.genero
                                    .descripcion
                                : ""
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Butaca</v-list-item-title>
                          <v-list-item-subtitle>
                            <h3 class="text-h6 text-md-h5 text-lg-h3 mt-1 mt-md-1 mt-lg-2">{{ postulante.butaca }}</h3>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <h4 class="grey--text mt-5">DATOS DE POSTULACIÓN</h4>
                  <v-row>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>ESFM/UA</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.institucion.nombre }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Especialidad</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.especialidad.descripcion }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        type="button"
                        large
                        color="info"
                        @click="printButaca"
                      >
                        Ver butaca
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con
                    <b>Cédula de Identidad y Fecha de Nacimiento</b>
                    ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-consultar-butaca",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    menu_date: false,
    type_state: 1,
    convocatoria: {
      id: 0,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    postulante: {
      a_postulante: {
        id: "",
        a_persona: {
          cedula_dni: "",
          cedula_dni_f: "",
          complemento: "",
          paterno: "",
          materno: "",
          nombres: "",
          nombre_completo: "",
          fecha_nacimiento: "",
        },
      },
      institucion: {},
      especialidad: {},
      butaca: "",
      id: "",
    },
  }),
  computed: {
    birthDateFormatted() {
      if (!this.postulante.a_postulante.a_persona.fecha_nacimiento) return null;

      const [year, month, day] =
        this.postulante.a_postulante.a_persona.fecha_nacimiento.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  created() {
    this.getConvocatoria();
  },
  methods: {
    ...mapActions(["toast"]),
    getConvocatoria() {
      this.notify_load = true;
      axios
        .get(
          Service.getUrlAPI() + "admision/verificar/convocatoria",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatoria = response.data
            ? response.data
            : { id: null, cite: "", descripcion: "", gestion: "" };
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    searchPostulante() {
      if (this.$refs.formp.validate()) {
        this.load_search = true;
        this.type_state = 1;
        axios
          .post(
            Service.getUrlAPI() + "admision/consultar/butaca",
            {
              convocatoria_id: this.convocatoria.id,
              modalidad_id :  1,
              tipo_convocatoria_id : 4,
              cedula_dni: this.postulante.a_postulante.a_persona.cedula_dni_f,
              fecha_nacimiento: this.postulante.a_postulante.a_persona.fecha_nacimiento,
              complemento: this.postulante.a_postulante.a_persona.complemento
            },
            Service.getHeader()
          )
          .then((response) => {
            this.load_search = false;
            if (response.status == 200) {
              this.type_state = 3;
              //this.postulante.convocatoria = response.data.convocatorium;
              this.postulante = response.data;
              this.postulante.a_postulante.a_persona.cedula_dni_f=this.postulante.a_postulante.a_persona.cedula_dni;
              /* this.postulante.institucion = response.data.institucion;
              this.postulante.especialidad = response.data.especialidad;
              this.postulante.id = response.data.id;
              this.postulante.butaca = response.data.butaca; */
              //this.postulante = response.data;
            } else {
              this.type_state = 2;
            }
          })
          .catch(() => {
            this.load_search = false;
            console.error("Postulante no encontrado");
          });
      } else {
        this.toast({
          color: "info",
          text: "Ingrese Cédula de identidad y Fecha de nacimiento",
        });
      }
    },

    printButaca() {
      let datos = this.postulante;
      let bodyPdf = PDFService.bodyButacaPDF(this.convocatoria, datos);
      let docDefinition = {
        watermark: {
          text: "Prueba Escrita",
          opacity: 0.06,
          angle: 315,
        },
        /*pageSize: {
          width: 500,
          height: 600,
        },*/
        pageSize: "A5",
        //"LETTER",
        pageMargins: [35, 68, 35, 20],
        header: PDFService.setting("left").header,
        content: bodyPdf,
      };
      //pdfMake.createPdf(docDefinition).download("NumeroButaca.pdf");
      pdfMake.createPdf(docDefinition).open();
    },
  },
};
</script>
<style lang="css" scoped>
.theme--light.v-pagination .v-pagination__item:focus {
  outline: 0;
}
</style>
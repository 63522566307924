<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Revisar evaluación de la lengua originaria"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  Lista de resultados de la <b>evaluación de la lengua originaria</b> para su
                  revisión, modalidad <b>A</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="3">
                      <v-select
                        label="ESFM/UA"
                        v-model="options.institucion_id"
                        :items="instituciones"
                        item-text="nombre"
                        item-value="id"
                        :rules="[(v) => !!v || 'Seleccione una opción']"
                        @change="changeInstitucion()"
                        hide-details
                        filled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="options.search"
                        label="CI, nombre, apellidos"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                    <td>
                      {{ item.persona.fecha_nacimiento | formatdate }}
                    </td>
                  </template>
                  <template v-slot:[`item.prueba_aptitud.nota`]="{ item }">
                    <td>
                      <span
                        v-if="item.prueba_aptitud.nota > 60"
                        class="font-weight-medium"
                      >
                        {{ item.prueba_aptitud.nota }}
                      </span>
                      <span v-else class="red--text font-weight-medium">
                        {{ item.prueba_aptitud.nota }}
                      </span>
                    </td>
                  </template>
                  <template v-slot:[`item.archivo`]="{ item }">
                    <td>
                      <a
                        v-if="item.prueba_aptitud"
                        :href="`${serve}aptitud/${item.prueba_aptitud.archivo}`"
                        target="_blank"
                        small
                        text
                      >
                        formulario
                      </a>
                    </td>
                  </template>
                  <template v-slot:[`item.estado`]="{ item }">
                    <td>
                      <v-chip
                        v-if="item.estado_id == 7"
                        small
                        color="blue lighten-3"
                      >
                        observado
                      </v-chip>
                      <v-chip
                        v-else-if="
                          item.prueba_aptitud && item.prueba_aptitud.nota > 60
                        "
                        small
                        color="green lighten-4"
                      >
                        aprobado
                      </v-chip>
                      <v-chip v-else small color="red lighten-4">
                        reprobado
                      </v-chip>
                    </td>
                  </template>
                  <!-- 
                  <template v-slot:[`item.accion`]="{ item }">
                    <td>
                        <v-btn
                        small
                        color="success"
                        @click="
                          confirmObservado(
                            item.prueba_aptitud,
                            item.id,
                            item.a_persona.paterno,
                            item.a_persona.materno,
                            item.a_persona.nombres
                          )
                        "
                      >
                        Observar
                      </v-btn> 
                    </td>
                  </template>
                -->
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
    <v-dialog v-model="controlDialog" width="350">
      <v-card>
        <v-form ref="formo" @submit.prevent="updateAptitud()">
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Alerta</span>
          </v-card-title>
          <v-card-text class="text-center">
            <p>
              ¿Hay alguna observación del/la postulante <br />
              <b class="blue--text">{{ postulante }}</b> de la
              <b>Evaluación de la lengua originaria</b>?
            </p>
            <p class="red--text">¿Está seguro/a de anular la prueba?</p>
            <v-row class="mt-1">
              <v-textarea
                outlined
                label="Detalle observación"
                v-model="prueba.observacion"
                :rules="[(v) => !!v || 'El campo es requerido']"
                no-resize
                rows="3"
              ></v-textarea>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success" :loading="load_send" text
              >Aceptar</v-btn
            >
            <v-btn color="red" text @click="controlDialog = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-verificar-caja",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_search: false,
    load_send: false,
    convocatoria: {
      id: null,
      cite: "",
      gestion: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      {
        text: "Apellidos y nombres",
        value: "a_persona.nombre_completo",
      },
      { text: "Cédula identidad", value: "a_persona.cedula_dni" },
      { text: "Fecha nacimiento", value: "a_persona.fecha_nacimiento" },
      { text: "Especialidad", value: "especialidad.descripcion" },
      { text: "Nota", value: "prueba_aptitud.nota" },
      { text: "Documento", value: "archivo", sortable: false },
      { text: "Estado", value: "estado", sortable: false },
      { text: "Acción", value: "accion", sortable: false },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    modalidades: [],
    postulantes: [],
    postulante: "",
    prueba: {
      id: "",
      observacion: "",
      convocatoria_id: "",
      postulante_id: "",
    },
    controlDialog: false,
    serve: "",
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [1, 12,13];
    if (this.user && roles.includes(this.user.rid)) {
      this.serve = Service.getServe();
      this.getParametricas();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getParametricas() {
      this.notify_load = true;
      axios
        .get(
          Service.getUrlAPI() + "admision/convocatoria/institucion",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatoria = response.data.convocatorias
            ? response.data.convocatorias[0]
            : { id: null, cite: "", gestion: "" };
          this.instituciones = response.data.instituciones;
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/reportados/aptitud",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    searchPostulantes() {
      this.load_search = true;
      if (this.$refs.formp.validate()) {
        this.getPostulantes();
        this.load_search = false;
      } else {
        this.load_search = false;
        this.toast({ color: "info", text: "Seleccione ESFM/UA" });
      }
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    changeInstitucion() {
      this.postulantes = [];
      this.options.page = 1;
      this.options.pageCount = 0;
      this.options.itemsPerPage = 10;
      this.getPostulantes();
    },

    confirmObservado(prueba, postulante_id, paterno, materno, nombres) {
      this.postulante = `${paterno} ${materno} ${nombres}`;
      this.prueba.observacion = prueba.observacion;
      this.prueba.convocatoria_id = this.options.convocatoria_id;
      this.prueba.postulante_id = postulante_id;
      this.controlDialog = true;
    },

    updateAptitud() {
      if (this.$refs.formo.validate()) {
        this.load_send = true;
        axios
          .post(
            Service.getUrlAPI() + "admision/prueba/aptitud/observado",
            this.prueba,
            Service.getHeader()
          )
          .then((response) => {
            this.load_send = false;
            this.prueba.id = "";
            this.prueba.observacion = "";
            if (this.$refs.formo) this.$refs.formo.reset();
            this.toast({ color: "success", text: response.data.message });
            this.controlDialog = false;
            this.getPostulantes();
          })
          .catch(() => {
            this.load_send = false;
            this.toast({ color: "error", text: "No se pudo confirmar" });
          });
      } else {
        this.toast({
          color: "info",
          text: "Ingrese la observación",
        });
      }
    },
  },
};
</script>
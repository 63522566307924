<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Inscritos modalidad B"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  Lista de postulantes inscritos en la modalidad
                  <b>B1, B2, B3, B4 y B5</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <!-- <v-card-title>Formulario de Postulación y Aptitud</v-card-title> -->
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="3">
                      <v-select
                        label="ESFM/UA"
                        v-model="options.institucion_id"
                        :items="instituciones"
                        item-text="nombre"
                        item-value="id"
                        :rules="[(v) => !!v || 'Seleccione una opción']"
                        @change="changeInstitucion()"
                        hide-details
                        filled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="options.search"
                        label="CI, nombre, apellidos"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                      <v-btn
                        class="ml-1"
                        type="button"
                        large
                        color="error"
                        :loading="load_report"
                        :disabled="postulantes.length == 0"
                        @click="reportAptitud"
                      >
                        <v-icon>mdi-file-download</v-icon>
                        PDF
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.a_persona.nombre_completo`]="{ item }">
                    <td>
                      {{ item.a_persona.paterno }} {{ item.a_persona.materno }}
                      {{ item.a_persona.nombres }}
                    </td>
                  </template>
                  <template v-slot:[`item.a_persona.fecha_nacimiento`]="{ item }">
                    <td>
                      {{ item.a_persona.fecha_nacimiento | formatdate }}
                    </td>
                  </template>
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-verificar-caja",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_search: false,
    load_report: false,
    convocatoria: {
      id: null,
      cite: "",
      gestion: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      { text: "Apellidos y nombres", value: "a_persona.nombre_completo" },
      { text: "Cédula identidad", value: "a_persona.cedula_dni" },
      { text: "Fecha nacimiento", value: "a_persona.fecha_nacimiento" },
      { text: "Teléfono/Celular", value: "a_persona.celular" },
      { text: "Especialidad", value: "especialidad.descripcion" },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    instituciones: [],
    postulantes: [],
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    if (this.user && (this.user.rid === 1||this.user.rid === 13)) {
      this.getParametricas();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getParametricas() {
      this.notify_load = true;
      axios
        .get(
          Service.getUrlAPI() + "admision/convocatoria/institucion",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatoria = response.data.convocatorias
            ? response.data.convocatorias[0]
            : { id: null, cite: "", gestion: "" };
          this.instituciones = response.data.instituciones;
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/inscritos/modalidadb",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    searchPostulantes() {
      if (this.$refs.formp.validate()) {
        this.getPostulantes();
      } else {
        this.toast({ color: "info", text: "Seleccione ESFM/UA" });
      }
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    changeInstitucion() {
      this.postulantes = [];
      this.options.page = 1;
      this.options.pageCount = 0;
      this.options.itemsPerPage = 10;
      this.getPostulantes();
    },

    reportAptitud() {
      this.load_report = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/reporte/inscritos/modalidadb",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          const institucion = this.instituciones.find(
            (item) => item.id == this.options.institucion_id
          );
          let bodyPdf = PDFService.bodyMbPDF(
            this.convocatoria,
            institucion,
            response.data.registro
          );
          var docDefinition = {
            pageSize: "LETTER",
            pageMargins: [40, 70, 40, 30],
            header: PDFService.setting().header,
            footer: PDFService.setting().footer,
            content: bodyPdf,
          };
          pdfMake.createPdf(docDefinition).download("InscritosModalidadB.pdf");
          this.load_report = false;
        })
        .catch((err) => {
          this.load_report = false;
          console.error(err);
        });
    },
  },
};
</script>
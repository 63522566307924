<template>
  <v-container grid-list-xl fluid>
    <v-row class="mb-2 mb-md-4 mb-lg-6 text-center">
      <v-col cols="12">
        <h3 class="text-h6 text-md-h5 text-lg-h3 mt-1 mt-md-1 mt-lg-2">
         
        </h3>
        <p class="text-subtitle-1 text-md-h5 text-lg-h4 mt-1 mt-md-2 mt-lg-3">
          REGISTRO EN LA BASE DE DATOS DEL BANCO DE PROFESIONALES PARA INVITACIÓN A CARGOS ACÉFALOS EN LAS ESCUELAS SUPERIORES DE FORMACIÓN DE MAESTRAS Y MAESTROS Y UNIDADES ACADÉMICAS GESTIÓN 2023
        </p>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Resultados a Cargos Convocados (ESFM/UA)
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/resultados002_esfm.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                CONVOCATORIA PÚBLICA ESFM/UA - N° 002/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                27/01/2023 
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/convocatoria_002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" md="4" sm="6">
          <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/EGI N° 004/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                31/01/2023 - ACLARATORIA DE SEDE ESFM "RIBERALTA" PROCESO DE COMPULSA DE MÉRITOS - ESFM/UA  
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/Comunicado_DGFM_EGI_No004_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card> 
      </v-col>
    
     
     
    </v-row> -->
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#F0F4C3" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                COMUNICADO CO/DGFM/RBP N° 001/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                27/02/2023
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/comunicado_bd_01_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                REGISTRO EN LA BASE DE DATOS DEL BANCO DE PROFESIONALES
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                25/02/2023
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/registro_bd_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Formulario N° 4, Declaración Jurada – Doble Percepción
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/FORMULARIO_4_DECLARACION_JURADA_DOBLE_PERCEPCION.docx"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<br/>

    
    
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>Nota importante</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Para usar el sistema se recomienda utilizar los siguientes
              navegadores web, a razón del uso de las nuevas tecnologías.
            </p>
            <p>Navegadores:</p>
            <div class="pl-5">
              <a href="https://www.google.com/intl/es/chrome/" target="_blank">
                <b>Chrome</b> en su ultima preferentemente
              </a>
              <br />
              <a
                href="https://www.mozilla.org/es-ES/firefox/new/"
                target="_blank"
              >
                <b>Firefox</b> en su ultima preferentemente
              </a>
              <br />
              <a href="https://www.opera.com/es" target="_blank">
                <b>Opera</b> en su ultima preferentemente
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "compulsa-documento",
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Formulario de Registro"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la postulante para ver o descargar su
                  <b>Formulario </b> previamente debe estar
                  registrado. Si no está registrado diríjase a la opción de
                  <b>Registro</b> del menú <b>Banco de Profesionales</b>.
                </p>
                <notify-text-ci></notify-text-ci>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <form-search
                  :bloading="load_search"
                  @sendApplicant="searchApplicant"
                ></form-search>
                <div v-if="type_state == 3">
                  <h4 class="grey--text">DATOS PERSONALES</h4>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Apellidos y Nombres</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            {{ postulante.c_persona.nombre_completo }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Genero</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.c_persona.genero.descripcion }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <h4 class="grey--text mt-5">DATOS DE POSTULACIÓN</h4>
                  <v-row>
                    <v-col cols="12" sm="6" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>ESFM/UA</v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              postulante.institucion.departamento.descripcion
                            }}
                            - {{ postulante.institucion.nombre }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Cargo</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.cargo.descripcion }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        type="button"
                        large
                        color="info"
                        @click="printFormVoucher"
                      >
                        Imprimir formulario
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con la
                    <b>Cédula Identidad o DNA y Fecha de Nacimiento</b>
                    ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="compulsa de méritos profesionales"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import NotifyTextCi from "@/components/NotifyTextCi";
import FormSearch from "../components/FormSearch.vue";
import Service from "../services/request";
import PDFService from "../utils/pdf";
import { mapActions } from "vuex";

export default {
  name: "certified-form",
  components: {
    HeaderTitle,
    NotifyCard,
    NotifyTextCi,
    FormSearch,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    type_state: 1,
    convocatoria: {
      id: 0,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    postulante: {
      id: "",
      fecha_registro: "",
      c_persona: {
        cedula_dni: "",
        rda: "",
        paterno: "",
        materno: "",
        nombres: "",
        fecha_nacimiento: "",
      },
      institucion: {},
      cargo: {},
    },
    postulante_completo: {    
    },
  }),
  created() {
    this.getAnnouncement();
  },
  methods: {
    ...mapActions(["toast"]),
    getAnnouncement() {
      this.notify_load = true;
      Service.svcCertifiesAnnouncementBanco()
        .then((response) => {
          this.convocatoria = response.data;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_title = "Notificación";
          this.notify_load = false;
        });
    },

    searchApplicant(search_data) {
      this.postulante.c_persona.cedula_dni = search_data.cedula_dni;
      this.postulante.c_persona.fecha_nacimiento = search_data.fecha_nacimiento;
      this.load_search = true;
      this.type_state = 1;
      Service.svcCertifiesSearchApplicant({
        convocatoria_id: this.convocatoria.id,
        cedula_dni: this.postulante.c_persona.cedula_dni,
        fecha_nacimiento: this.postulante.c_persona.fecha_nacimiento,
      })
        .then((response) => {
          this.load_search = false;
          if (response.data.postulante.id == null) {
            this.type_state = 2;
          } else {
            this.type_state = 3;
            this.postulante = response.data.postulante;
            this.postulante_completo = response.data;
          }
        })
        .catch(() => {
          this.load_search = false;
        });
    },

    printFormVoucher() {
      PDFService.certifiedApplicationFormBanco(this.convocatoria, this.postulante_completo);
    },
  },
};
</script>

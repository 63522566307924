import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store";

let paths = [
  {
    path: "*",
    meta: { public: true },
    redirect: { path: "/404" },
  },
  {
    path: "/404",
    meta: { public: false },
    name: "not-found",
    component: () => import(`@/views/NotFound.vue`),
  },
  {
    path: "/",
    meta: {},
    name: "root",
    component: () => import(`@/views/Dashboard.vue`),
  },
  {
    path: "/perfil",
    meta: { public: false, requiresAuth: true },
    name: "profile",
    component: () => import(`@/views/Profile.vue`),
  },
  {
    path: "/guia/institucionalizacion",
    meta: { public: true },
    name: "guia-institucionalizacion",
    component: () => import(`@/views/InstitutionalizationGuide.vue`),
  },
  {
    path: "/institucionalizacion/evaluacion",
    meta: { public: true },
    name: "institucionalizacion-evaluacion",
    component: () => import(`@/views/InstitucionalizacionEvaluacion.vue`),
  },
  {
    path: "/guia/admision",
    meta: { public: true },
    name: "guia-admision",
    component: () => import(`@/views/AdmissionGuide.vue`),
  },
  //  {
  //   path: "/admision/documento",
  //   meta: { public: true },
  //   name: "admision-documentos",
  //   component: () => import(`@/views/AdmisionDocumento.vue`),
  // }, 
  //   {
  //   path: "/admision/inscripcion",
  //   meta: { public: true },
  //   name: "admision-inscripcion",
  //   component: () => import(`@/views/AdmisionInscripcion.vue`),
  // }, 
  //  {
  //   path: "/admision/registro",
  //   meta: { public: true },
  //   name: "admision-registro-a",
  //   component: () => import(`@/views/AdmisionRegistroA.vue`),
  // },
  // {
  //   path: "/admision/registros",
  //   meta: { public: true },
  //   name: "admision-registro-b",
  //   component: () => import(`@/views/AdmisionRegistroB.vue`),
  // }, 
  //  {
  //   path: "/admision/impresion",
  //   meta: { public: true, requiresAuth: false },
  //   name: "admision-impresion",
  //   component: () => import(`@/views/AdmisionImpresion.vue`),
  // }, 
  //  {
  //   path: "/admision/modificar",
  //   meta: { public: true },
  //   name: "admision-modificar",
  //   component: () => import(`@/views/AdmisionModificarDato.vue`),
  // },
  // {
  //   path: "/admision/verificar/deposito",
  //   meta: { public: false, requiresAuth: true },
  //   name: "admision-verificar-deposito",
  //   component: () => import(`@/views/AdmisionVerificarDeposito.vue`),
  // }, 
  {
    path: "/admision/verificado/caja",
    meta: { public: false, requiresAuth: true },
    name: "admision-verificado-caja",
    component: () => import(`@/views/AdmisionVerificadoCaja.vue`),
  },
  {
    path: "/admision/verificado/diario/caja",
    meta: { public: false, requiresAuth: true },
    name: "admision-verificado-caja",
    component: () => import(`@/views/AdmisionVerificadoDiarioCaja.vue`),
  },
   {
    path: "/admision/lista/postulante/b",
    meta: { public: false, requiresAuth: true },
    name: "admision-lista-postulante-b",
    component: () => import(`@/views/AdmisionListaPostulanteB.vue`),
  },
  {
    path: "/admision/lista/postulante/bdgfm",
    meta: { public: false, requiresAuth: true },
    name: "admision-lista-postulante-bdgfm",
    component: () => import(`@/views/AdmisionListaPostulanteBDGFM.vue`),
  },
///
  // {
  //   path: "/admision/registrar/requisito",
  //   meta: { public: false, requiresAuth: true },
  //   name: "admision-registrar-requisito",
  //   component: () => import(`@/views/AdmisionRegistroRequisitoB.vue`),
  // },
  // {
  //   path: "/admision/editar/requisito",
  //   meta: { public: false, requiresAuth: true },
  //   name: "admision-editar-requisito",
  //   component: () => import(`@/views/AdmisionEditarRequisitoB.vue`),
  // }, 
///
  {
    path: "/admision/habilitados/aptitud",
    meta: { public: false, requiresAuth: true },
    name: "admision-habilitados-aptitud",
    component: () => import(`@/views/AdmisionHabilitadosAptitud.vue`),
  },
  {
    path: "/admision/inscritos/modalidadb",
    meta: { public: false, requiresAuth: true },
    name: "admision-inscritos-modalidadb",
    component: () => import(`@/views/AdmisionInscritosModalidadB.vue`),
  },
  {
    path: "/admision/habilitados/aptitud/esfm",
    meta: { public: false, requiresAuth: true },
    name: "admision-habilitados-aptitud-esfm",
    component: () => import(`@/views/AdmisionHabilitadosAptitudEsfm.vue`),
  },
  {
    path: "/admision/inscritos/modalidad/esfm/b",
    meta: { public: false, requiresAuth: true },
    name: "admision-inscritos-modalidad-b",
    component: () => import(`@/views/AdmisionInscritosModalidadBEsfm.vue`),
  },
  {
    path: "/admision/habilitados/aptitud/gral",
    meta: { public: false, requiresAuth: true },
    name: "admision-habilitados-aptitud-gral",
    component: () => import(`@/views/AdmisionHabilitadosAptitudGral.vue`),
  },
   {
    path: "/admision/registro/aptitud",
    meta: { public: false, requiresAuth: true },
    name: "admision-registro-aptitud",
    component: () => import(`@/views/AdmisionRegistroAptitud.vue`),
  }, 
  {
    path: "/admision/reporte/aptitud/computo",
    meta: { public: false, requiresAuth: true },
    name: "admision-reporte-aptitud-computo",
    component: () => import(`@/views/AdmisionReporteAptitudComputo.vue`),
  },
  {
    path: "/admision/revisar/aptitud",
    meta: { public: false, requiresAuth: true },
    name: "admision-revisar-aptitud",
    component: () => import(`@/views/AdmisionRevisarAptitud.vue`),
  },
  {
    path: "/admision/revisar/habilitado/escrita",
    meta: { public: false, requiresAuth: true },
    name: "admision-revisar-habilitado-escrita",
    component: () => import(`@/views/AdmisionDepurarHabilitadoEscrita.vue`),
  },
  {
    path: "/admision/procesar/resultado",
    meta: { public: false, requiresAuth: true },
    name: "admision-procesar-resultado",
    component: () => import(`@/views/AdmissionProcessResult.vue`),
  },
  {
    path: "/admision/tablero",
    meta: { public: false, requiresAuth: true },
    name: "admission-dashboard",
    component: () => import(`@/views/AdmissionDashboard.vue`),
  },
  {
    path: "/admision/informe/estadistico",
    meta: { public: false, requiresAuth: true },
    name: "admission-statistical-report",
    component: () => import(`@/views/AdmissionStatisticalReport.vue`),
  },
  {
    path: "/admision/reporte/institucion",
    meta: { public: true, requiresAuth: false },
    name: "admision-reporte-institucion",
    component: () => import(`@/views/AdmisionReporteInstitucion.vue`),
  },
  {
    path: "/admision/reporte/esfm",
    meta: { public: true, requiresAuth: false },
    name: "admision-reporte-esfm",
    component: () => import(`@/views/AdmisionReporteEsfmUa.vue`),
  },
  {
    path: "/admision/habilitados/escrita",
    meta: { public: false, requiresAuth: true },
    name: "admision-habilitados-escrita",
    component: () => import(`@/views/AdmisionHabilitadosEscrita.vue`),
  },
  {
    path: "/admision/generar/butaca",
    meta: { public: false, requiresAuth: true },
    name: "admision-generar-butaca",
    component: () => import(`@/views/AdmisionGenerarButaca.vue`),
  },
  {
    path: "/admision/consultar/butaca",
    meta: { public: true, requiresAuth: false },
    name: "admission-check-seat",
    component: () => import(`@/views/AdmisionConsultarButaca.vue`),
  },
  {
    path: "/admision/consultar/resultado",
    meta: { public: true, requiresAuth: false },
    name: "admission-check-result",
    component: () => import(`@/views/AdmissionCheckResult.vue`),
  },
  {
    path: "/admision/consultar/resultadoB",
    meta: { public: true, requiresAuth: false },
    name: "admission-check-result",
    component: () => import(`@/views/AdmissionCheckResultB.vue`),
  },
  {
    path: "/admision/informe/estadistico",
    meta: { public: true, requiresAuth: false },
    name: "admision-informe-estadistico",
    component: () => import(`@/views/AdmissionStatisticalReport.vue`),
  },
  {
    path: "/guia/compulsa",
    meta: { public: true },
    name: "guia-compulsa",
    component: () => import(`@/views/CertifiedGuide.vue`),
  },
  //compulsa ESFM
  {
    path: "/compulsa/documento",
    meta: { public: true },
    name: "compulsa-documento",
    component: () => import(`@/views/CompulsaDocumento.vue`),
  },
  //  {
  //   path: "/compulsa/inscripcion",
  //   meta: { public: true },
  //   name: "certified-inscription",
  //   component: () => import(`@/views/CertifiedInscription.vue`),
  // },
    // {
    //   path: "/compulsa/modificar",
    //   meta: { public: true },
    //   name: "compulsa-modificar",
    //   component: () => import(`@/views/CompulsaModificarDato.vue`),
    // },
  //  {
  //   path: "/compulsa/formulario",
  //   meta: { public: true },
  //   name: "certified-application-form",
  //   component: () => import(`@/views/CertifiedApplicationForm.vue`),
  // }, 
  // {
  //   path: "/compulsa/certificado",
  //   meta: { public: true },
  //   name: "compulsa-certificado",
  //   component: () => import(`@/views/CertifiedProcessCertificate.vue`),
  // },
  {
    path: "/compulsa/respuestas",
    meta: { public: true },
    name: "compulsa-certificado",
    component: () => import(`@/views/CertifiedProcessUPResponses.vue`),
  },
  //compulsa UP
  {
    path: "/compulsaUP/documento",
    meta: { public: true },
    name: "compulsaUP-documento",
    component: () => import(`@/views/CompulsaUPDocumento.vue`),
  },
  // {
  //   path: "/compulsaUP/inscripcion",
  //   meta: { public: true },
  //   name: "certifiedUP-inscription",
  //   component: () => import(`@/views/CertifiedUPInscription.vue`),
  // },
  {
    path: "/compulsaUP/formulario",
    meta: { public: true },
    name: "certifiedUP-application-form",
    component: () => import(`@/views/CertifiedUPApplicationForm.vue`),
  },
  //BANDO PROFESIONALES
  // {
  //   path: "/banco/documento",
  //   meta: { public: true },
  //   name: "banco-documento",
  //   component: () => import(`@/views/BancoInscription.vue`),
  // },
    {
    path: "/banco/inscripcion",
    meta: { public: true },
    name: "banco-inscription",
    component: () => import(`@/views/BancoDocumento.vue`),
  },
  {
    path: "/banco/formulario",
    meta: { public: true },
    name: "banco-application-form",
    component: () => import(`@/views/BancoApplicationForm.vue`),
  }, 
  {
    path: "/edp/resultado",
    meta: { public: false, requiresAuth: true },
    name: "edp-resultado",
    component: () => import(`@/views/EdpResultado.vue`),
  },
];
Vue.use(Router);
const router = new Router({
  base: "/",
  mode: "hash",
  // mode: "history",
  linkActiveClass: "active",
  routes: paths,
});

router.afterEach(() => {
  NProgress.done();
});
// const openRoutes = ["login"];
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      //return;
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;

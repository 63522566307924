<template>
    <div>
      <v-container grid-list-xl fluid>
        <header-title title="Lista verificados"></header-title>
        <div v-if="convocatoria.id > 0">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <p class="subtitle-1 font-weight-bold">
                    {{ convocatoria.cite }}
                  </p>
                  <p>
                    Lista de postulantes verificados por la/el responsable de caja
                    de la modalidad <b>A</b>.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-form ref="formp" @submit.prevent="getPostulantes()">
                    <v-row class="mt-1">
                      <v-col cols="12" sm="3">
                        <v-menu v-model="menu_date" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="birthDateFormatted" label="Fecha de reporte" append-icon="mdi-calendar"
                                :rules="[(v) => !!v || 'El campo es requerido']" filled readonly v-bind="attrs" v-on="on">
                            </v-text-field>
                            </template>
                            <v-date-picker v-model.trim="options.fecha" @input="menu_date = false" locale="es">
                            </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="options.search"
                          label="CI o DNI, nombre, apellidos"
                          :rules="[(v) => !!v || '']"
                          @input="changeSearch"
                          hint="1234567 o 1234567-1G"
                          hide-details
                          clearable
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-btn
                          class="mr-1"
                          type="submit"
                          large
                          color="primary"
                          :loading="load_search"
                        >
                          <v-icon>mdi-magnify</v-icon>
                          Buscar
                        </v-btn>
                        <v-btn
                          type="button"
                          large
                          color="error"
                          :loading="load_report"
                          :disabled="postulantes.length == 0"
                          @click="reportVerificado"
                        >
                          <v-icon>mdi-file-download</v-icon>
                          PDF
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="postulantes"
                    :page.sync="options.page"
                    :items-per-page="options.itemsPerPage"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:[`item.num`]="{ item }">
                      <td>
                        {{
                          (options.page - 1) * options.itemsPerPage +
                          (postulantes.indexOf(item) + 1)
                        }}
                      </td>
                    </template>
                    <template v-slot:[`item.nombre`]="{ item }">
                      <td>
                        {{ item.a_persona.paterno }} {{ item.a_persona.materno }}
                        {{ item.a_persona.nombres }}
                      </td>
                    </template>
                    <template v-slot:[`item.fecha_nacimiento`]="{ item }">
                      <td>
                        {{ item.a_persona.fecha_nacimiento | formatdate }}
                      </td>
                    </template>
                    <template v-slot:[`item.fecha_deposito`]="{ item }">
                      <td>
                        {{ item.deposito.fecha | formatdate }}
                      </td>
                    </template>
                  </v-data-table>
                  <div class="v-data-footer">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <div class="v-data-footer__select">
                          Filas por página:
                          <v-select
                            :items="[10, 20, 30, 50, 100]"
                            v-model="options.itemsPerPage"
                            @change="changePerPage()"
                            hide-details
                            class="my-0"
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="8" class="text-center">
                        <v-pagination
                          v-model="options.page"
                          :length="options.pageCount"
                          :total-visible="5"
                          @input="changePage"
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="type_state === 2">
                    <span class="red--text">
                      No hay registro con la
                      <b>Cédula Identidad o DNA</b> ingresada.
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <notify-card
            :title="notify_title"
            :load="notify_load"
            process="el proceso de admisión"
          ></notify-card>
        </div>
      </v-container>
      <v-dialog v-model="confirmDialog" width="300">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Alerta</span>
          </v-card-title>
          <v-card-text class="text-center">
            <!-- <p class="text-center">¿Está seguro(a) de confirmar el registro?</p> -->
            <p>
              ¿Está seguro(a) de habilitar al postulante
              <b class="blue--text">{{ postulante }}</b> para la
              <b>Evaluacion de lengua originaria o Escrita</b>?
            </p>
            <p>Dando su VoBo de que el <b>depósito bancario</b> es correcto.</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="load_send"
              text
              @click="checkPostulante()"
              >Aceptar</v-btn
            >
            <v-btn color="red" text @click="confirmDialog = false"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import HeaderTitle from "@/components/HeaderTitle";
  import NotifyCard from "@/components/NotifyCard";
  import Service from "../utils/general";
  import PDFService from "../utils/pdf";
  import pdfMake from "pdfmake/build/pdfmake";
  import pdfFonts from "pdfmake/build/vfs_fonts";
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  import { mapActions } from "vuex";
  import axios from "axios";
  
  export default {
    name: "admision-verificado-diario-caja",
    components: {
      HeaderTitle,
      NotifyCard,
    },
    data: () => ({
      menu_date: false,
      notify_title: "Cargando",
      notify_load: true,
      loading: false,
      load_search: false,
      load_send: false,
      load_report: false,
      type_state: 1,
      cedula_dni: "",
      convocatoria: {
        id: null,
        cite: "",
      },
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "num",
        },
        { text: "Apellidos y nombres", value: "nombre" },
        { text: "Cédula identidad", value: "a_persona.cedula_dni" },
        { text: "Complemento", value: "a_persona.complemento" },
        { text: "Fecha nacimiento", value: "fecha_nacimiento" },
        { text: "Celular", value: "a_persona.celular" },
        { text: "Especialidad", value: "especialidad.descripcion" },
        { text: "Nro. depósito", value: "deposito.numero" },
        { text: "Fecha depósito", value: "fecha_deposito" },
      ],
      options: {
        search: "",
        convocatoria_id: "",
        institucion_id: "",
        sigla: "A",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        fecha:"",
      },
      postulantes: [],
      postulante: "",
      postulante_id: "",
      confirmDialog: false,
      user: null,
    }),
    created() {
      this.user = Service.getUser();
      if (this.user == null) {
        this.$router.replace({ name: "root" });
      }
    },
    mounted() {
      const roles = [9, 10, 11];
      if (this.user && roles.includes(this.user.rid)) {
        this.options.institucion_id = this.user.ies;
        this.options.fecha = new Date().toISOString().slice(0,10);
        this.getModalidades();
      } else {
        this.$router.replace({ name: "root" });
      }
    },
    computed: {
        birthDateFormatted() {
        if (!this.options.fecha) return null;

        const [year, month, day] = this.options.fecha.split("-");
        return `${day}/${month}/${year}`;
        },
    },
    methods: {
      ...mapActions(["toast"]),
      getModalidades() {
        this.notify_load = true;
        axios
          .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
          .then((response) => {
            this.convocatoria = response.data.convocatoria
              ? response.data.convocatoria
              : { id: null, cite: "" };
            this.options.convocatoria_id = this.convocatoria.id;
            this.notify_title = "Notificación";
            this.notify_load = false;
            this.getPostulantes();
          })
          .catch(() => {
            this.notify_load = false;
            console.error("Parametros no encontrado");
          });
      },
  
      getPostulantes() {
        console.log(this.options.fecha);
        //admision/postulantes
        this.loading = true;
        axios
          .post(
            Service.getUrlAPI() + "admision/verificado/diario/caja",
            this.options,
            Service.getHeader()
          )
          .then((response) => {
            this.loading = false;
            this.postulantes = response.data.rows;
            if (response.data.count > 0) {
              this.options.pageCount =
                Math.floor(response.data.count / this.options.itemsPerPage) + 1;
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error("Error al cargar registros", error);
          });
      },
  
      changeSearch() {
        if (this.options.search && this.options.search.length > 2) {
          this.getPostulantes();
        }
      },
  
      changePerPage() {
        this.options.page = 1;
        this.getPostulantes();
      },
  
      changePage() {
        this.getPostulantes();
      },
  
      reportVerificado() {
        this.load_report = true;
        axios
          .post(
            Service.getUrlAPI() + "admision/verificado/diario/cajapdf",
            this.options,
            Service.getHeader()
          )
          .then((response) => {
            const currentDate = new Date().toISOString();
            const institucion = response.data.esfm;
            let bodyPdf = [
              {
                stack: [
                  {
                    marginTop: -20,
                    fontSize: 6,
                    text: `Fecha actual\n${PDFService.formatDate(currentDate)}`,
                    alignment: "right",
                  },
                  {
                    fontSize: 14,
                    bold: true,
                    color: "#212121",
                    text: this.convocatoria.cite,
                    alignment: "center",
                  },
                  {
                    fontSize: 12,
                    bold: true,
                    color: "#424242",
                    text: `POSTULANTES VERIFICADOS POR CAJA - ${PDFService.formatDate(this.options.fecha)}`,
                    alignment: "center",
                  },
                  {
                    fontSize: 11,
                    bold: true,
                    color: "#424242",
                    text: `MODALIDAD ${this.options.sigla} - ${this.convocatoria.gestion}`,
                    alignment: "center",
                  },
                  {
                    marginTop: 8,
                    fontSize: 9,
                    columns: [
                      {
                        width: "9%",
                        bold: true,
                        text: "Departamento: ",
                        style: "header",
                      },
                      {
                        width: "16%",
                        text: institucion
                          ? institucion.departamento.descripcion
                          : "",
                      },
                      {
                        width: "6.5%",
                        bold: true,
                        text: "ESFM/UA: ",
                      },
                      {
                        width: "68.5%",
                        text: institucion ? institucion.nombre : "",
                      },
                    ],
                  },
                ],
              },
            ];
            let tableContent = {
              marginTop: 5,
              fontSize: 8,
              layout: "lightHorizontalLines",
              table: {
                headerRows: 1,
                widths: ["3%", "33%", "13%", "32%", "10%", "10%"],
                body: [
                  [
                    {
                      text: "#",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                    {
                      text: "Apellidos y Nombres",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                    {
                      text: "Cédula identidad",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                    {
                      text: "Especialidad",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                    {
                      text: "Nro. depósito",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                    {
                      text: "Fecha depósito",
                      bold: true,
                      fillColor: "#dddddd",
                    },
                  ],
                ],
              },
            };
            let nombre = "";
            response.data.registro.forEach((item, index) => {
              nombre = `${item.a_persona.paterno ? item.a_persona.paterno : ""} ${
                item.a_persona.materno ? item.a_persona.materno : ""
              } ${item.a_persona.nombres}`;
              tableContent.table.body.push([
                index + 1,
                nombre,
                item.a_persona.cedula_dni,
                item.especialidad.descripcion,
                item.deposito.numero,
                PDFService.formatDate(item.deposito.fecha),
              ]);
            });
            bodyPdf.push(tableContent);
            var docDefinition = {
              pageSize: "LETTER",
              pageOrientation: "landscape",
              pageMargins: [40, 70, 40, 35],
              header: PDFService.setting().header,
              footer: PDFService.setting().footer,
              content: bodyPdf,
            };
            pdfMake
              .createPdf(docDefinition)
              .download("Verificados-Mod" + this.options.sigla + ".pdf");
            // pdfMake.createPdf(docDefinition).open();
            this.load_report = false;
          })
          .catch((err) => {
            this.load_report = false;
            console.error(err);
          });
      },
      formatDate(fecha) {
      if (!fecha) return null;

      const [year, month, day] = fecha.substring(0, 10).split("-");
      return `${day}/${month}/${year}`;
        },
    },
  };
  </script>
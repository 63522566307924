export default {
  strencdec(str, k) {
    let str_encode = "";
    for (let index = 0; index < str.length; index++) {
      var a = str.charCodeAt(index);
      var b = a ^ k;
      str_encode = str_encode + String.fromCharCode(b);
    }
    return str_encode;
  },
  getServe() {
    if (process.env.NODE_ENV === "production") {
      return "https://dgfmapi.minedu.gob.bo/";
    } else {
      return "http://127.0.0.1:4000/";
    }
  },
  getUrlAPI() {
    return this.getServe() + "api/";
  },
  getLogin() {
    return this.getServe() + "usuario/auth";
  },
  setToken(token) {
    return sessionStorage.setItem("token", token);
  },
  getToken() {
    return sessionStorage.getItem("token") || "null";
  },
  removeToken() {
    return sessionStorage.removeItem("token");
  },
  getTokenExt() {
    return "oyJhbGciOiJIUzUxMiIsInR5cCI6Ik9URUMifQ.eyJzdWIiOiIxNjIzNDA2NDc5NjIyMCIsIm5hbWUiOiJZeHVKKnpHcTBVbVV1YyNxV2FzcSIsImlhdCI6OTg5MjM1MTYyMzkwMjJ9.w4IyJ1e_evWB8p3IumcJKHTrjAI5bmGwDGo7_VJtb0lferWyF9bRW7V2cRP8UVtP0GJoWivD1_ZWMs4wu4jzxQ";
  },
  setUser(user) {
    return sessionStorage.setItem(
      "tyuia",
      this.strencdec(
        JSON.stringify({
          rid: user.iro,
          uid: user.ius,
          ies: user.ies,
          name: user.name,
          rol: user.rol,
        }),
        5
      )
    );
  },
  getUser() {
    return sessionStorage.getItem("tyuia")
      ? JSON.parse(this.strencdec(sessionStorage.getItem("tyuia"), 5))
      : null;
  },
  removeUser() {
    return sessionStorage.removeItem("tyuia");
  },
  setParams(convocatoria_id, cargo_id, codigo_institucion) {
    let params = JSON.stringify({
      convocatoria_id: convocatoria_id,
      cargo_id: cargo_id,
      codigo_institucion: codigo_institucion,
    });
    return localStorage.setItem("params", params);
  },
  getParams() {
    return localStorage.getItem("params")
      ? JSON.parse(localStorage.getItem("params"))
      : null;
  },
  removeParams() {
    return localStorage.removeItem("params");
  },
  setCodeInst(codeInst) {
    return localStorage.setItem("code", codeInst);
  },
  getCodeInst() {
    return localStorage.getItem("code");
  },
  removeCodeInst() {
    return localStorage.removeItem("code");
  },
  setTime(timePassed) {
    return localStorage.setItem("time", timePassed);
  },
  getTime() {
    return localStorage.getItem("time") ? localStorage.getItem("time") : null;
  },
  removeTime() {
    return localStorage.removeItem("time");
  },
  getQuestion() {
    return localStorage.getItem("question")
      ? JSON.parse(localStorage.getItem("question"))
      : null;
  },
  removeQuestion() {
    return localStorage.removeItem("question");
  },
  removeAll() {
    this.removeQuestion();
    this.removeParams();
    // this.removeTime();
  },

  //Set admision modalidades
  setModality(value) {
    return sessionStorage.setItem("md", value);
  },
  getModality() {
    return sessionStorage.getItem("md") ? sessionStorage.getItem("md") : null;
  },
  removeModality() {
    return sessionStorage.removeItem("md");
  },
  getHeader() {
    let myHeader = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getTokenExt(),
      },
    };
    if (this.getToken() != "null") {
      myHeader = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      };
    }
    return myHeader;
  },
  getHeaderExt() {
    let myHeader = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getTokenExt(),
      },
    };
    return myHeader;
  },
};
